.ng-select.ng-select-single .ng-select-container,
.ng-select .ng-select-container {
    min-height: 40px;
    max-height: fit-content;
    border-radius: 8px;
    border: 1px solid #dee2e6;
}

ng-select.dropdown {

    &--height-45 {
        .ng-select-container {
            min-height: 45px !important;
        }
    }

    &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
        border: 1px solid #b0b0b0;
        box-shadow: none;
    }

    .ng-value-label {
        font-weight: 500;
    }
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border: 2px solid #b0b0b0;
    box-shadow: none;
}

