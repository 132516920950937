.calculator {
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background-color: wheat;
    .display {
      width: 100%;
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      border-radius: 3px;
      padding: 5px;
      margin-bottom: 10px;
      text-align: right;
      font-size: 18px;
    }
    .buttons {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 5px;
      &--margin-bottom {
        margin-bottom: 5px;
      }
      button {
        padding: 10px;
        font-size: 16px;
        background-color: rgb(243, 199, 119);
        cursor: pointer;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
