.create {
  position: fixed;
  bottom: 64px;
  right: 64px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  
  &__button {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #247ba0;
    color: #ddd;
    font-size: 52px;
    font-weight: 600;
    box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.5);
  }
}

@media screen and (max-width: 820px) {
  .create {
    bottom: 50px;
    width: 100px;
    height: 100px;
  
    &__button {
      bottom: 60px;
      right: 34px;
      width: 52px;
      height: 52px;
    }
  }
}