.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;

    &__page-item-btn {
        background: transparent;
        border-radius: 50%;
        cursor: pointer;
        padding: 0;
        font-size: 18px;  
        width: 22px;
        height: 26px;
        margin: 4px;

        &.active {
            background-color: rgb(17, 141, 131); 
            color: white;
        }
    }

    &__prev-btn {
        padding-left: 8px;
        transform: rotate(180deg); 
        font-size: 20px;

        &:hover {
            color: rgb(17, 141, 131);
            cursor: pointer;
        }
    }

    &__next-btn {
        padding-left: 8px;
        font-size: 20px;

        &:hover {
            color: rgb(17, 141, 131);
            cursor: pointer;
        }
    }

  }

  button:focus {
    outline: none;
  }
  

  @media screen and (max-width: 430px) {
    .pagination {
        justify-content: flex-start;

        .page-link {
            padding: 8px;
            font-size: 12px !important;  
        }
    }
  }