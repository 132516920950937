.confirm-modal,
.new-user-modal,
.confirm-delete-modal,
.reset-password-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  height: 100vh;
  margin: 0 auto; 
}

.nycblinds-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  height: 100vh;
  margin: 0 auto; 
}

.nycblinds-invoice-item-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  height: 100vh;
  margin: 0 auto;

  .modal-body {
    height: calc(100vh - 452px) !important;
  }
}
