// /* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "assets/styles/modals.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "assets/styles/custom-select.scss";
@import "assets/styles/pagination.scss";
@import "assets/styles/order-list-pagination.scss";
@import "assets/styles/calculator.scss";
@import "assets/styles/order-item-form.scss";
@import "assets/styles/create-button.scss";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  // ::-webkit-scrollbar {
  //   -webkit-appearance: none;
  //   width: 7px;
  // }
  // ::-webkit-scrollbar-thumb {
  //   border-radius: 4px;
  //   background-color: rgba(0, 0, 0, .5);
  //   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  // }

  .highlighted-text {
    background-color: #ff0;
  }
}

// body {
//   * {
//     display: block;
//   }
// }

