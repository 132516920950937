.order-item-form {

  &__row {
    display: flex;
    gap: 30px;
  }

  &__row-element {
    width: 100%;
  }

  &__input-container {
    position: relative;
  }

  &__input-icon {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 24px;
    height: 24px;
  }

  &__input {
    width: 100%;
    height: 40px;
    padding: 6px 16px;
    color: black;
    background-color: white;
    border: 1px solid rgb(226 232 240);
    border-radius: 8px;

    &:focus {
      outline-color: #b0b0b0;
    }

    &--without-height {
      height: unset;
    }

    &--dollar {
      padding: 6px 16px 6px 40px;
    }
  }

  &__select {
    -webkit-appearance: none;
  }

  &__block-title {
    font-size: 18px;
    padding: 16px 0 10px;
  }

  &__mult-select-container {
    display: flex;
    align-items: center;
    white-space: normal;
  }

  &__mult-select-checkbox {
    margin: 0 14px 2px 0;
  }

  &__mult-select-quantity-container {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__mult-select-quantity {
    margin: 0 6px;
    color: black;
    font-size: 18px;
    font-weight: 400;
  }

  &__mult-select-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #0676ff;
    color: white;
    font-size: 16px;
  }

  &__links-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 6px;
  }

  &__link {
    font-size: 16px;

    &:hover {
      text-decoration: none;
      color: #8b8b8b;
    }
  }

  &__notes {
    width: 100%;
    padding: 6px 16px;
    color: black;
    background-color: white;
    border: 1px solid rgb(226 232 240);
    border-radius: 8px;

    &:focus {
      outline-color: #b0b0b0;
    }
  }
}
