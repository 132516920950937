.page-item.active .page-link {
  background: black;
  color: white;
  border-color: black;
}

.page-item .page-link {
  color: black;
  outline: none;
  box-shadow: none;
}

.pagination {
  position: fixed;
  bottom: 20px;
  width: 100%;
}